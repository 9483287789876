<template>
  <div class="task-list m-t">
    <u-table
        ref="table"
        color="primary"
        :data="data"
        :columns="columns"
        :visible-columns="visibledColumns"
        row-key="id"
        :pagination.sync="options.serverPagination"
        selection="multiple"
        :loading="options.loading"
        class="task-table"
        @request="request"
        :rows-per-page-options="[20, 50, 100]"
        :hide-no-results-label="true"
    >
      <u-tr slot="header" slot-scope="props">
<!--        <u-th auto-width>
          <u-checkbox
              v-model="props.selected"
              :indeterminate="props.partialSelected"
              size="sm"
          />
        </u-th>-->
        <u-th v-for="col in props.cols" :key="col.name" :props="props">
          <template>
            {{ col.label }}
          </template>
          <div v-if="col.name === 'options'" class="text-center">
            <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat
                   no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
          </div>
        </u-th>
      </u-tr>

      <u-tr slot="body" slot-scope="props"
            :props="props">
<!--        <u-td class="pos-rlt" auto-width>
        </u-td>-->
        <u-td style="max-width: 50px; min-width: 50px; width: 50px" key="id" :props="props">
          {{ props.row.id }}
        </u-td>
        <u-td key="nome" :props="props">
          <a @click="abrir(props.row)" v-if="props.row.person" class="person-avatar-name ">
            <div class="person-avatar avatar md inline">
              <img v-if="props.row.person.photo" width="25" class="" :src="props.row.person.photo"/>
              <div v-else class="img-fake">{{ firstLettersName(props.row.person.name) }}</div>
            </div>
            <span class="name">{{props.row.person.name}}</span>
          </a>
        </u-td>
        <u-td key="position" :props="props">
          <div v-if="props.row.aclGroup">{{ props.row.aclGroup.name }}</div>
        </u-td>
        <u-td key="status" :props="props">
          <span v-if="props.row.active" class="u-chip u-chip-small bg-green font-12 text-white m-l-xs">Ativo</span>
          <span v-else class="u-chip u-chip-small bg-negative font-12 text-white m-l-xs">Inativo</span>
        </u-td>
        <u-td class="text-center" key="options" :props="props">
          <e-btn-table-options v-if="false">
            <menu-options>
              <ul>
                <menu-options-item close label="Editar" @click="abrir(props.row)" />
                <menu-options-item close label="Remover" @click="remover(props.row)" />
              </ul>
            </menu-options>
          </e-btn-table-options>
        </u-td>
      </u-tr>
    </u-table>
  </div>
</template>

<script>
import {listName, listStorage} from "./config/list"
import listOptions from "@/reuse/list/listOptions"
import {LocalStorage, UCheckbox, UTable, UTd, UTh, UTr, debounce} from "uloc-vue"
import {EBtnTableOptions} from "@/plugins/uloc-erp"
import MenuOptions from "components/layout/context-menu/context-window-options"
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'
import windowMember from '../window/member'
import ProjetoPageMixin from "components/suporte/components/projetos/ProjetoPageMixin"
import {deleteMember} from "@/domain/project/services/members"
export default {
  name: "MembersList",
  mixins: [ProjetoPageMixin],
  props: {
    data: {
      type: Array
    },
    request: {
      type: Function
    },
    load: {
      type: Function
    },
    options: {
      type: Object
    }
  },
  data () {
    return {
      listName: listName,
      listStorage: this.listStorage,
      columns: this.listStorage.map(o => {
        return {
          ...o,
          field: o.name,
          align: 'left'
        }
      }).concat([
        {
          name: 'options',
          required: true,
          label: '',
          field: 'options',
          sortable: false
        }
      ])
    }
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  watch: {
    'options.filters.busca': debounce(function () {
      this.load()
    }, 500)
  },
  methods: {
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      console.log('Ok', newConfig)
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    },
    windowMember: windowMember,
    abrir (t) {
      return
      this.windowMember(t.id, this.projeto)
    },
    remover (t) {
      this.confirmaExcluir(t)
    },
    confirmaExcluir (member) {
      this.$uloc.dialog({
        title: 'Atenção',
        message: `Tem certeza que deseja excluir o membro ${member.person.name} deste projeto?`,
        cancel: true,
        color: 'negative'
      }).then(data => {
        this.$uloc.notify({color: 'warning', message: 'Excluindo membro'})
        this.excluir(member)
      }).catch(() => {
      })
    },
    excluir (member) {
      deleteMember(this.projeto.id, member.id)
          .then(response => {
            console.log(response)
            this.$uloc.notify({color: 'positive', message: 'Membro excluído!'})
            this.load()
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  },
  components: {
    UTable,
    UTh,
    UTr,
    UTd,
    EBtnTableOptions,
    MenuOptions,
    MenuOptionsItem,
  }
}
</script>
