<script>
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import {UPopover} from "uloc-vue"
import ProjetoPageMixin from "components/suporte/components/projetos/ProjetoPageMixin"
import MembersList from "components/suporte/components/projetos/membros/List"
import {listMembers as list} from "@/domain/project/services/members"
import windowMember from "components/suporte/components/projetos/window/member"
export default {
  name: 'ProjetoMembros',
  mixins: [ProjetoPageMixin],
  components: {
    MembersList,
    MenuOptionsItem,
    UPopover
  },
  data () {
    return {
      table: {
        serverData: [],
        loading: false,
        filters: {
          busca: null,
          id: null,
          active: [true],
        },
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 20
        }
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
  },
  methods: {
    request({pagination, filter}) {

      let extraFilters = []
      this.table.filters.busca && extraFilters.push(`&search=${this.table.filters.busca}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'name'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : false}${extraFilters.join('')}`)

      list(this.projeto.id, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    },
    windowMember: windowMember,
    novo () {
      this.windowMember(null, this.projeto)
    },
    editar (member) {
      this.windowMember(member, this.projeto)
    }
  },
  meta: {
    title: 'Membros do Projeto',
    name: 'Membros do Projeto'
  }
}
</script>

<template>
  <div class="">
    <ul class="snav">
      <li><a @click="$router.push({name: 'suporte.dashboard'})">Suporte</a></li>
      <li class="disable">Membros</li>
    </ul>
    <div class="stitle">
      <h2>Membros do projeto</h2>
      <div class="sopts">
        <u-btn v-if="false" @click="novo" label="Adicionar membro" no-caps color="blue-8"/>
        <u-btn v-if="false" flat size="sm" class="m-l-xs">
          <i class="fa fa-ellipsis-h font-14" />
          <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 15]" style="min-width: 172px">
            <div>
              <ul>
                <menu-options-item label="Configurações" close  />
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </div>
    </div>
    <members-list :data="table.serverData" :request="request" :load="load" :options="table" />
  </div>
</template>
